import styled from 'styled-components';
import NextLink from 'next/link';

/**
 * Button
 */
interface IButton {
  $secondary?: boolean;
  $small?: boolean;
  $disabled?: boolean;
}

// Filled
export const Button = styled(NextLink)<IButton>`
  font-family: ${({ theme }) => theme.fonts.interactive};
  font-size: ${(props) => (props.$small ? '1.6rem' : '2.4rem')};
  display: inline-flex;
  border-radius: 2px;
  border: 1px solid transparent;
  text-decoration: none;
  padding: ${(props) => (props.$small ? '8px 16px' : '8px 24px')};

  color: ${(props) =>
    props.$secondary ? ({ theme }) => theme.colors.gray900 : 'white'};
  background-color: ${(props) =>
    props.$secondary
      ? ({ theme }) => theme.colors.gray100
      : ({ theme }) => theme.colors.coralInteractive};

  box-shadow: ${(props) =>
    props.$secondary ? '0px 0px 5px #1A18160D' : '1px 3px 5px #803B331A'};

  transition: 0.3s;

  &:hover {
    background-color: ${(props) =>
      props.$secondary
        ? ({ theme }) => theme.colors.gray200
        : ({ theme }) => theme.colors.coral400};

    box-shadow: ${(props) =>
      props.$secondary ? '0px 0px 11px #1A18162B' : '0px 0px 10px #803b3333'};
  }

  &:focus {
    background-color: ${(props) =>
      props.$secondary
        ? ({ theme }) => theme.colors.gray100
        : ({ theme }) => theme.colors.coralInteractive};

    border: 1px solid
      ${(props) =>
        props.$secondary
          ? ({ theme }) => theme.colors.gray300
          : ({ theme }) => theme.colors.coral400};
  }

  &.disabled,
  &:disabled {
    pointer-events: none;

    background-color: ${(props) =>
      props.$secondary
        ? ({ theme }) => theme.colors.gray050
        : ({ theme }) => theme.colors.coral200};
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: 1.6rem;
    padding: 8px 16px;
  }
`;

// Outline
export const ButtonOutline = styled(NextLink)<IButton>`
  font-family: ${({ theme }) => theme.fonts.interactive};
  font-size: ${(props) => (props.$small ? '1.6rem' : '2.4rem')};
  display: inline-flex;
  border-radius: 2px;
  text-decoration: none;
  padding: ${(props) => (props.$small ? '8px 16px' : '8px 24px')};

  color: ${(props) =>
    props.$secondary ? 'white' : ({ theme }) => theme.colors.coralInteractive};
  border: 1px solid
    ${(props) =>
      props.$secondary
        ? 'white'
        : ({ theme }) => theme.colors.coralInteractive};

  box-shadow: 1px 3px 5px #e5695b1a;
  transition: 0.3s;

  &:hover {
    color: ${(props) =>
      props.$secondary
        ? ({ theme }) => theme.colors.gray050
        : ({ theme }) => theme.colors.coral600};

    background-color: ${(props) =>
      props.$secondary
        ? ({ theme }) => theme.colors.gray050
        : ({ theme }) => theme.colors.coral050};

    box-shadow: 0px 0px 10px #803b3333;
  }

  &:focus {
    background-color: ${(props) =>
      props.$secondary ? ({ theme }) => theme.colors.gray050 : 'transparent'};

    border: 1px solid
      ${(props) =>
        props.$secondary
          ? ({ theme }) => theme.colors.gray050
          : ({ theme }) => theme.colors.coral600};
  }

  &.disabled,
  &:disabled {
    pointer-events: none;

    color: ${(props) =>
      props.$secondary
        ? ({ theme }) => theme.colors.gray050
        : ({ theme }) => theme.colors.coral300};

    background-color: ${(props) =>
      props.$secondary
        ? ({ theme }) => theme.colors.gray050
        : ({ theme }) => theme.colors.coral200};
  }
`;

// Text
export const ButtonText = styled(NextLink)<IButton>`
  font-family: ${({ theme }) => theme.fonts.interactive};
  font-size: ${(props) => (props.$small ? '1.6rem' : '2.4rem')};
  font-weight: 500;
  color: ${(props) =>
    props.$secondary
      ? ({ theme }) => theme.colors.navalInteractive
      : ({ theme }) => theme.colors.coralInteractive};
  text-decoration: none;
  transition: 0.3s;

  &:hover {
    text-shadow: ${(props) =>
      props.$secondary ? '0px 0px 10px #22425C33' : '0px 0px 10px #e5695b33'};
  }

  &:focus {
    color: ${(props) =>
      props.$secondary
        ? ({ theme }) => theme.colors.navalInteractive700
        : ({ theme }) => theme.colors.coral600};
    text-shadow: ${(props) =>
      props.$secondary ? '0px 0px 5px #22425C0D' : '0px 0px 5px #E5695B0D'};
  }
`;

// Common Link
export const TextLink = styled(NextLink)`
  color: ${({ theme }) => theme.colors.navalInteractive600};
  text-decoration-thickness: 2px;
  text-underline-offset: 2px;
`;

/**
 * Title
 */
interface ITitle {
  $small?: boolean;
}
export const Title = styled.h1<ITitle>`
  font-family: ${({ theme }) => theme.fonts.heading};
  font-size: ${({ $small }) => ($small ? '3.2rem' : '4.8rem')};
  color: ${({ theme }) => theme.colors.naval700};
  font-weight: 500;
  line-height: ${({ $small }) => ($small ? '3.84rem' : '5.76rem')};
  margin-bottom: 16px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: 3.2rem;
    line-height: 3.5rem;
  }
`;

/**
 * Subtitle
 */
export const Subtitle = styled.h2`
  font-family: ${({ theme }) => theme.fonts.heading};
  font-size: 2.4rem;
  font-weight: 300;
  color: ${({ theme }) => theme.colors.gray700};
  line-height: 3.5rem;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: 2rem;
    line-height: 2.4rem;
  }
`;

/**
 * Title Section
 */
interface ITitleSection {
  $small?: boolean;
}
export const TitleSection = styled.h3<ITitleSection>`
  font-family: ${({ theme }) => theme.fonts.heading};
  font-size: ${({ $small }) => ($small ? '1.6rem' : '2.4rem')};
  font-weight: 500;
  color: ${({ theme }) => theme.colors.gray900};
  margin-bottom: 24px;
`;

/**
 * Subtitle Section
 */
interface ISubtitleSection {
  $secondary?: boolean;
  $big?: boolean;
  $primaryColor?: boolean;
}
export const SubtitleSection = styled.p<ISubtitleSection>`
  display: block;
  margin-left: 12px;
  font-family: ${({ theme }) => theme.fonts.heading};
  font-size: ${({ $big }) => ($big ? '3.2rem' : '2rem')};
  font-weight: 500;
  color: ${({ $primaryColor }) =>
    $primaryColor
      ? ({ theme }) => theme.colors.naval700
      : ({ theme }) => theme.colors.gray900};
  position: relative;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: ${({ $big }) => ($big ? '8px' : '5px')};
    left: -12px;
    width: 4px;
    height: ${({ $big }) => ($big ? '32px' : '20px')};
    background-color: ${({ $secondary }) =>
      $secondary
        ? ({ theme }) => theme.colors.naval700
        : ({ theme }) => theme.colors.coral500};
  }
`;

/**
 * Small Text
 */
export const SmallText = styled.p`
  font-weight: 300;
  font-size: 1.2rem;
  color: ${({ theme }) => theme.colors.gray700};
`;

/**
 * Cards
 */
export const Card = styled.div`
  padding: 24px;
  border-radius: 2px;
  border: 1px solid ${({ theme }) => theme.colors.gray200};
  background-color: ${({ theme }) => theme.colors.gray025};
  box-shadow: 0px 2px 5px #00000017;
  max-width: 428px;
  width: 100%;
`;

export const CardText = styled.p`
  color: ${({ theme }) => theme.colors.gray700};
`;

/**
 * Inputs
 */
export const Label = styled.label`
  display: inline-block;
  font-family: ${({ theme }) => theme.fonts.interactive};
  color: ${({ theme }) => theme.colors.gray900};
  font-weight: 600;
  margin-bottom: 5px;
`;

export const Input = styled.input`
  border: 1px solid ${({ theme }) => theme.colors.gray400};
  border-radius: 2px;
  padding: 8px 16px;
  width: 100%;
  font-family: ${({ theme }) => theme.fonts.interactive};
  font-size: 1.6rem;
  color: ${({ theme }) => theme.colors.gray800};
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  resize: none;

  &::placeholder {
    color: ${({ theme }) => theme.colors.gray600};
  }

  &:active {
    color: ${({ theme }) => theme.colors.navalInteractive700};
    border: 1px solid ${({ theme }) => theme.colors.navalInteractive500};
  }

  &:focus {
    /* color: ${({ theme }) => theme.colors.navalInteractive700}; */
    border: 1px solid ${({ theme }) => theme.colors.navalInteractive700};
    box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.navalInteractive700};
    outline: 0;
  }

  // Validation style
  &:required:invalid,
  &:invalid,
  &:focus:invalid,
  &.input-error,
  &:focus.input-error {
    padding-right: 3.2rem;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2Ij4KICA8ZyBpZD0iR3J1cG9fNTU1MiIgZGF0YS1uYW1lPSJHcnVwbyA1NTUyIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwIC0wLjM5OSkiPgogICAgPHJlY3QgaWQ9IlJldMOibmd1bG9fMzExNyIgZGF0YS1uYW1lPSJSZXTDom5ndWxvIDMxMTciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMCAwLjM5OSkiIGZpbGw9Im5vbmUiLz4KICAgIDxwYXRoIGlkPSJFbGlwc2VfOTA0IiBkYXRhLW5hbWU9IkVsaXBzZSA5MDQiIGQ9Ik02LjE1NC0uNUE2LjY1NCw2LjY1NCwwLDEsMS0uNSw2LjE1NCw2LjY2MSw2LjY2MSwwLDAsMSw2LjE1NC0uNVptMCwxMi4zMDhBNS42NTQsNS42NTQsMCwxLDAsLjUsNi4xNTQsNS42Niw1LjY2LDAsMCwwLDYuMTU0LDExLjgwOFoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDEuODQ2IDIuMjQ1KSIgZmlsbD0iI2JiMWEyNyIvPgogICAgPHBhdGggaWQ9IkxpbmhhXzQ0NCIgZGF0YS1uYW1lPSJMaW5oYSA0NDQiIGQ9Ik0uNSw0LjE5MmgtMVYtLjVoMVoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDggNS4zMjIpIiBmaWxsPSIjYmIxYTI3Ii8+CiAgICA8Y2lyY2xlIGlkPSJFbGlwc2VfOTA1IiBkYXRhLW5hbWU9IkVsaXBzZSA5MDUiIGN4PSIwLjc1IiBjeT0iMC43NSIgcj0iMC43NSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNy4yNSAxMC4zOTkpIiBmaWxsPSIjYmIxYTI3Ii8+CiAgPC9nPgo8L3N2Zz4K);
    background-position: right calc(1rem) center;
    background-repeat: no-repeat;
    border: 1px solid ${({ theme }) => theme.colors.danger600};
    box-shadow: none;
  }
`;

export const CustomSelect = styled(Input)`
  // Validation style
  &:required:invalid,
  &:invalid,
  &:focus:invalid,
  &.input-error,
  &:focus.input-error {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2Ij4KICA8ZyBpZD0iR3J1cG9fNTU1MiIgZGF0YS1uYW1lPSJHcnVwbyA1NTUyIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwIC0wLjM5OSkiPgogICAgPHJlY3QgaWQ9IlJldMOibmd1bG9fMzExNyIgZGF0YS1uYW1lPSJSZXTDom5ndWxvIDMxMTciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMCAwLjM5OSkiIGZpbGw9Im5vbmUiLz4KICAgIDxwYXRoIGlkPSJFbGlwc2VfOTA0IiBkYXRhLW5hbWU9IkVsaXBzZSA5MDQiIGQ9Ik02LjE1NC0uNUE2LjY1NCw2LjY1NCwwLDEsMS0uNSw2LjE1NCw2LjY2MSw2LjY2MSwwLDAsMSw2LjE1NC0uNVptMCwxMi4zMDhBNS42NTQsNS42NTQsMCwxLDAsLjUsNi4xNTQsNS42Niw1LjY2LDAsMCwwLDYuMTU0LDExLjgwOFoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDEuODQ2IDIuMjQ1KSIgZmlsbD0iI2JiMWEyNyIvPgogICAgPHBhdGggaWQ9IkxpbmhhXzQ0NCIgZGF0YS1uYW1lPSJMaW5oYSA0NDQiIGQ9Ik0uNSw0LjE5MmgtMVYtLjVoMVoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDggNS4zMjIpIiBmaWxsPSIjYmIxYTI3Ii8+CiAgICA8Y2lyY2xlIGlkPSJFbGlwc2VfOTA1IiBkYXRhLW5hbWU9IkVsaXBzZSA5MDUiIGN4PSIwLjc1IiBjeT0iMC43NSIgcj0iMC43NSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNy4yNSAxMC4zOTkpIiBmaWxsPSIjYmIxYTI3Ii8+CiAgPC9nPgo8L3N2Zz4K);
    background-position: right calc(1.6rem) center;
    background-repeat: no-repeat;
    border: 1px solid ${({ theme }) => theme.colors.danger600};
    box-shadow: none;
  }
`;

export const InputMessage = styled.p`
  font-size: 1.2rem;
  color: ${({ theme }) => theme.colors.danger600};
`;
