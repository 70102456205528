import Page from '../src/screens/Home';

/**
 * Home page component
 *
 * @author Lucas Santana | Leme Inteligência Forense
 * @returns JSX.Element
 */

export default function Index(): JSX.Element {
  return Page();
}
